/* Sentry */
import * as Sentry from '@sentry/react'

/* Other */
import { MantineProvider, localStorageColorSchemeManager } from '@mantine/core'
import { Notifications } from '@mantine/notifications'
import '@mantine/notifications/styles.css'
import { useGetDevice } from './hooks/useGetDevice'

import React, { useEffect } from 'react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'

import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev'
import { AppRouter } from './routes/AppRouter'

import { App as CapacitorApp } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import { Geolocation } from '@capacitor/geolocation'
import { LiveUpdates } from '@components/liveUpdates/LiveUpdates'
import { ChangelogFullscreenModal } from '@components/modals/changelogModal/ChangelogFullscreenModal'
import { LocationPermissionFullscreenModal } from '@components/modals/locationPermission/LocationPermissionFullscreenModal'
import { SwayError } from '@components/swayStates/SwayError'
import { ThemeContextProvider } from '@context/themeContext'
import { ProfileType } from '@graphql'
import { useIsMobileViewport } from '@hooks/useIsMobileViewport'
import { ModalsProvider } from '@mantine/modals'
import SafeAreaProvider from '@providers/SafeAreaProvider'
import useAuthStore from '@stores/useAuthStore'
import useGeolocationStore from '@stores/useGeolocationStore'
import { useScreenDimensionsStore } from '@stores/useScreenDimensionsStore'
import { IntercomProvider } from 'react-use-intercom'
import { baseSwayTheme } from './theme/baseSwayTheme'
import { baseSwayThemeVariablesResolver } from './theme/baseSwayThemeVariablesResolver'

if (
  import.meta.env.MODE === 'development' ||
  import.meta.env.MODE === 'integration'
) {
  // Adds messages only in a dev environment
  console.info('running in dev mode')
  loadDevMessages()
  loadErrorMessages()
}

const sentryDSN = import.meta.env.VITE_SENTRY_DSN
const sentryENV = import.meta.env.VITE_SENTRY_ENV
const INTERCOM_APP_ID = import.meta.env.VITE_ROOT_INTERCOM_APP_ID

function setupSentry(buildId?: string) {
  if (sentryDSN) {
    try {
      Sentry.init({
        dsn: sentryDSN,
        environment: sentryENV,
        integrations: [
          Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          }),
          Sentry.replayIntegration(),
          Sentry.feedbackIntegration({
            colorScheme: 'system',
            autoInject: false,
            formTitle: 'Contact support',
            submitButtonLabel: 'Submit',
            messagePlaceholder:
              'Describe the problem and any steps to reproduce it.',
            isEmailRequired: true,
            showBranding: false,
          }),
        ],
        tracesSampleRate: 0.1,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        release: buildId,
        tracePropagationTargets: [
          'localhost',
          'https://sway.dm',
          'https://swaydm.app',
          /^\//,
        ],
      })
    } catch (error) {
      console.error('[App] Sentry unable to init: ', error)
    }
  }
}

if (Capacitor.isNativePlatform()) {
  // Set up Sentry for Error Reporting
  CapacitorApp.getInfo().then((result) => {
    const build = result.build
    const version = result.version

    setupSentry(`v${version}-b${build}`)
  })

  // Check for permissions for Native functionality
  Geolocation.checkPermissions().then((permission) => {
    if (permission.location === 'granted') {
      useGeolocationStore.getState().setPermissions(permission)
      useGeolocationStore.getState().setPermissionsChecked(true)
    } else {
      useGeolocationStore.getState().setPermissions(permission)
      useGeolocationStore
        .getState()
        .setPermissionsChecked(!permission.location.startsWith('prompt'))
    }
  })
} else {
  // TODO: Add a build id for web
  setupSentry()
}

const colorSchemeManager = localStorageColorSchemeManager({
  key: 'my-app-color-scheme',
})

colorSchemeManager.set('light')

const theme = baseSwayTheme
const themeResolver = baseSwayThemeVariablesResolver

const App: React.FC = () => {
  // Initialize what device we're on
  useGetDevice()
  const { safeAreaInsets } = useScreenDimensionsStore()

  const { currentUser } = useAuthStore()
  const isMobile = useIsMobileViewport()

  return (
    <MantineProvider
      theme={theme}
      colorSchemeManager={colorSchemeManager}
      cssVariablesResolver={themeResolver}
    >
      <Sentry.ErrorBoundary
        fallback={SwayError}
        beforeCapture={(scope) => {
          scope.setTag('location', 'App Main')
        }}
      >
        <ModalsProvider>
          <ThemeContextProvider storageKey="sway-color-theme">
            <LiveUpdates />
            <SafeAreaProvider />
            <Notifications
              style={{
                pointerEvents: 'none',
              }}
              styles={{
                notification: {
                  pointerEvents: 'auto',
                },
                root: {
                  marginTop: safeAreaInsets.top,
                },
              }}
              position={isMobile ? 'top-right' : 'bottom-right'}
            />
            <IntercomProvider appId={INTERCOM_APP_ID}>
              <AppRouter />

              {currentUser && (
                <>
                  <LocationPermissionFullscreenModal />
                  <ChangelogFullscreenModal
                    isCommunityFollower={
                      currentUser.profileType == ProfileType.CommunityFollower
                    }
                  />
                </>
              )}
            </IntercomProvider>
          </ThemeContextProvider>
        </ModalsProvider>
      </Sentry.ErrorBoundary>
    </MantineProvider>
  )
}

export default App
